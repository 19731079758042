<template>
    <div class="viewMode">

        <div
            class="viewMode__btn"
            :class="{'viewMode__btn-active': isTable}"
            @click="setTable()">
            <SvgUse :width="18" :height="18" id="viewModeTable" key="viewModeTable"></SvgUse>
        </div>

        <div
            class="viewMode__btn"
            :class="{'viewMode__btn-active': isGrid}"
            @click="setGrid()">
            <SvgUse :width="18" :height="18" id="viewModeGrid" key="viewModeGrid"></SvgUse>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'ToggleViewMode',
        props: {
            mode: {
                type: String,
                default: 'table',
            }
        },
        data() {
            return {};
        },
        computed: {
            isTable() {
                return this.mode === 'table'
            },
            isGrid() {
                return this.mode === 'grid'
            },
        },
        methods: {
            setTable() {
                this.$emit('switch', 'table')
            },
            setGrid() {
                this.$emit('switch', 'grid')
            },
        },
        beforeMount() {
        }
    };
</script>

<style lang="scss">
    
    @import '../scss/utils/mixins.scss';
    @import '../scss/utils/variables.scss';

    .viewMode{
        height: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        order: 3;
        &__btn{
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 38px;
            height: 38px;
            opacity: .5;
            transition: .2s opacity ease;
            &-active{
                opacity: 1;
                transition: .2s opacity ease;
                cursor: default;
            }
        }
    }


</style>