<template>
    <div class="offers__header-table_content">

        <template v-if="viewMode==='table'">
            <!-- Table view -->
            <div  class="offers__table-default-block">
                <table class="offers__table-row-gap">
                    
                    <thead class="offers__table-row-gap__header">
                        <tr>
                            <th>
                                <div
                                    @click="$emit('sortByName')"
                                    class="offers__table-row-gap__header_sort-block">
                                    <div class="wv-table-sort__icon">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7.73534 14.5117C7.86697 14.6675 8.0804 14.6675 8.21203 14.5117L11.7868 10.281C12 10.0286 11.8474 9.5973 11.5459 9.59999L4.44993 9.59998C4.15087 9.60264 4.00265 10.0307 4.21413 10.281L7.73534 14.5117Z"
                                                :fill="sortByName==='asc' ? '#333333' : '#b1b1b1'"
                                            />
                                            <path
                                                d="M8.26564 1.4883C8.134 1.33251 7.92058 1.33251 7.78895 1.4883L4.21422 5.71901C4.00101 5.97135 4.15359 6.4027 4.45511 6.40001L11.551 6.40002C11.8501 6.39736 11.9983 5.96931 11.7868 5.71903L8.26564 1.4883Z"
                                                :fill="sortByName==='desc' ? '#333333' : '#b1b1b1'"
                                            />
                                        </svg>
                                    </div>
                                    <span>{{ $lng.__('lk_webmaster_offers', 'Offer name') }}</span>
                                </div>
                            </th>
                            <th>
                                <div class="offers__table-row-gap__header_sort-block">
                                    <span>{{ $lng.__('lk_webmaster_offers', 'Category') }}</span>
                                </div>
                            </th>
                            <th>
                                <div class="offers__table-row-gap__header_sort-block">
                                    <span>{{ $lng.__('lk_webmaster_offers', 'Geo') }}</span>
                                </div>
                            </th>
                            <th>
                                <div class="offers__table-row-gap__header_sort-block">
                                    <span>{{ $lng.__('lk_webmaster_offers', 'Rate') }}</span>
                                </div>
                            </th>
                            <!--<th>AAR</th>-->
                            <th></th>
                        </tr>
                    </thead>
                    
                    <tbody class="offers__table-row-gap__body">
                        <tr v-for="offer in offers">
                            <td class="offers__table-row-gap__name">
                                <div class="offers__table-row-gap__name-block">
                                    <div class="offers__table-row-gap__name_logo">
                                        <img
                                            v-if="(typeof offer.photo !== 'object')"
                                            :src="'https://webvork.com'+offer.photo"
                                            :alt="offer.name"
                                        />
                                        <div
                                            v-if="offer.is_top"
                                            class="offers__table-row-gap__name__icon">
                                            <SvgUse :width="20" :height="20" id="fire" key="icon-fire"></SvgUse>
                                        </div>
                                    </div>
                                    <div class="offers__table-row-gap__name_info">
                                        <h3 class="offers__table_offer-name">
                                            {{ offer.name }}
                                            <span class="offers__pill-id">
                                                ID: {{ offer.id }}
                                            </span>
                                        </h3>
                                        <p class="offers__table-row-gap__name_offer-description">
                                            {{ offer.description }}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="offers__table-row-gap__category">
                                <div class="offers__table-row-gap__category-block">
                                    <div
                                        v-for="category in offer.category"
                                        class="wv-tag--blue">
                                        {{ category }}
                                    </div>
                                </div>
                            </td>
                            <td class="offers__table-row-gap__geo">
                                <div class="offers__table-row-gap__geo-block">
                                    <div
                                        v-for="payment in offer.payments"
                                        class="offers__table-row-gap__geo_info">
                                        <div class="offers__table-row-gap__geo__icon">
                                            <SvgUse :width="18" :height="18" :id="'geo'+payment.geo_code"></SvgUse>
                                        </div>
                                        <p class="offers__table-row-gap__geo_name pl-10">
                                            {{ payment.geo }}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            
                            <!-- Payments -->
                            <td class="offers__table-row-gap__payments">
                                <div class="offers__table-row-gap__payments-block">
                                    
                                    <div
                                        v-for="payment in offer.payments"
                                        class="offers__table-row-gap__payments_price">
                                        
                                        <div class="table_row-gap__payments_money">
                                            {{ payment.rate.amount }} {{ payment.rate.currency ? payment.rate.currency : '&euro;'}}
                                        </div>

                                        <div v-if="payment.bonus" class="offers__table-row-gap__payments_webcoins">
                                            <img
                                                src="/images/table-webcoins-logo.svg"
                                                alt="Webvorkcoins logo"
                                            />
                                            <span>{{ payment.bonus }}</span>
                                        </div>

                                    </div>
                                </div>
                            </td>
                            
<!--                            <td class="offers__table-row-gap__approve">
                                <div class="offers__table-row-gap__approve-block">
                                    <h4 class="offers__table-row-gap__approve_percent">
                                        {{ offer.average_ar }} %
                                    </h4>
                                    <p class="offers__table-row-gap__approve_description">
                                        {{ $lng.__('lk_webmaster_offers', 'Average approve rate') }}
                                    </p>
                                </div>
                            </td>-->
                            
                            
                            <td class="offers__table-row-gap__button">
                                <div
                                    v-if="$store.getters['auth/isUserApproved']"
                                    class="offers__table-row-gap__button-block">
                                    <a
                                        href="#"
                                        @click.prevent='$router.push("/"+$route.params.lng+"/webmaster/streams/create/"+offer.id)'
                                        class="wv-btn--green offers__table-row-gap__button_create">
                                        {{ $lng.__('lk_webmaster_offers', 'Create stream') }}
                                    </a>
                                </div>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </template>
        
    
        <template v-if="viewMode==='grid'">
            
            <div class="offers__card-default-block">
                <div
                    v-for="offer in offers"
                    class="offers__card-default">
                    <div class="offers__card-default__content">
                        <a
                            href="/"
                            class="offers__card-default_link">
                            
                            <div class="offers__card-default__logo">
                                <img
                                    v-if="(typeof offer.photo !== 'object')"
                                    :src="'https://webvork.com'+offer.photo"
                                    :alt="offer.name" />
                                <div
                                    v-if="offer.is_top"
                                    class="offers__card-default__logo__icon">
                                    <SvgUse :width="20" :height="20" id="fire" key="icon-fire"></SvgUse>
                                </div>
                            </div>
                            
                            <div class="offers__card-default__name_info">
                                <h3 class="offers__card-default__offer-name">
                                    {{ offer.name}}
                                    <span class="offers__pill-id">
                                        ID: {{ offer.id }}
                                    </span>
                                </h3>
                                <p class="offers__card-default__name_offer-description">
                                    {{ offer.description }}
                                </p>
                            </div>
                        </a>
                        
                        <div class="offers__card-default__category-block">
                            <a
                                v-for="category in offer.category"
                                href="#"
                                class="offers__card-default__offer-tag wv-tag--blue">
                                {{ category }}
                            </a>
                        </div>
                        
                        <div class="offers__card-default__geo-block">
                            <div
                                v-for="payment in offer.payments"
                                class="offers__card-default__geo_info">
                                <div class="offers__card-default__geo_country">
                                    <div class="offers__card-default__geo__icon">
                                        <SvgUse :width="18" :height="18" :id="'geo'+payment.geo_code"></SvgUse>
                                    </div>
                                    <p class="offers__table-row-gap__geo_name pl-10">
                                        {{ payment.geo }}
                                    </p>
                                </div>
                                <div class="offers__table-row-gap__payments_price">
                                    <div class="table_row-gap__payments_money">
                                        {{ payment.rate.amount }} {{ payment.rate.currency ? payment.rate.currency : '&euro;'}}
                                    </div>
                                    <div v-if="payment.bonus > 0" class="offers__table-row-gap__payments_webcoins">
                                        <img src="/images/table-webcoins-logo.svg" alt="Webvorkcoins logo" />
                                        <span>{{ payment.bonus }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="$store.getters['auth/isUserApproved']"
                            class="offers__card-default__button-block">
                            <a
                                href="#"
                                @click.prevent='$router.push("/"+$route.params.lng+"/webmaster/streams/create/"+offer.id)'
                                class="wv-btn--green offers__card-default__button_create">
                                {{ $lng.__('lk_webmaster_offers', 'Create stream') }}
                            </a>
                        </div>
                    </div>
                </div>
                
            </div
                >
        </template>
        
        
        
        
    </div>
</template>

<script>
    export default {
        name: 'Offers',
        props: {
            viewMode: {
                type: String,
                default: 'table'
            },
            offers: {
                type: Array,
                default: []
            },
            sortByName: {
                type: String,
                default: 'asc'
            },
        },
        data() {
            return {}
        },
        methods: {},
        beforeMount() {
        }
    };
</script>

<style lang="scss">
    
    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';
    
    
    .offers{
        &__pill-id{
            display: inline-flex;
            font-family: $font-secondary;
            font-size: 12px;
            line-height: 22px;
            border: 1px solid $color-day-line;
            border-radius: 12px;
            padding: 0 10px;
            margin: 0 0 0 5px;
            font-weight: normal;
            white-space: nowrap;
        }
    }
    
    // Общая шапка таблицы
    .offers__header-table {
        &-block {
            @include grid--100;
            display: flex;
            flex-direction: column;
            background-color: transparent;
            order: 6;
            color: $color-day-black;
            // overflow: hidden;
        }
        
        &_header {
            width: 100%;
            height: 90px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: $color-day-white;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            margin-top: $grid-gap-20;
            padding: 0 30px;
            
            @include maxWidth(1200) {
                height: 140px;
                flex-wrap: wrap;
            }
            @include maxWidth(510) {
                height: 90px;
                padding: 0 20px;
            }
            @include maxWidth(450) {
                height: 50px;
            }
        }
        &_title {
            display: flex;
            align-items: center;
            margin-right: auto;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.005em;
            order: 1;
            @include maxWidth(450) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        &_icon {
            margin-left: 10px;
            @include maxWidth(450) {
                display: none;
            }
        }
        &_content {
            width: 100%;
        }
        &_toggle-view {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: auto;
        }
    }
    
    // Table
    .offers__table-row-gap {
        width: 100%;
        border-collapse: collapse;
    }
    .offers__table_offer-name{
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: 0.005em;
        color: $color-text-default;
    }
    .offers__table-row-gap, .offers__card-default {
        &__sort-block {
            display: inline-flex;
            align-items: center;
        }
        thead {
            border-bottom: 5px solid $color-day-bg;
            font-family: $font-secondary;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.5px;
            tr {
                height: 60px;
                text-align: left;
                position: relative;
                z-index: 1;
                th {
                    padding-left: 32px;
                    background-color: $color-day-white;
                    text-align: left;
                    // Не работает с overflow
                    position: sticky;
                    top: 0;
                    z-index: $z-index-form - 2;
                }
            }
        }
        tbody {
            background-color: $color-day-white;
            height: 100%;
            tr {
                transition: background-color 0.2s;
                &:hover {
                    background-image: linear-gradient(to right, #ffffff, $color-day-table);
                }
            }
            td {
                height: 260px;
                padding-left: 30px;
                padding-top: 20px;
                padding-bottom: 20px;
                border-bottom: 3px solid $color-day-bg;
            }
        }
        
        &--not-active {
            opacity: 0.4;
        }
        
        &__name {
            min-width: 500px;
            
            &-block {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
            }

            &__icon{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                background: white;
                border-radius: 3px;
                box-shadow: 0 1px 2px rgb(0 0 0 / 19%);
                position: absolute;
                bottom: -15px;
                right: 0px;
            }
            
            &_logo {
                width: 130px;
                min-width: 130px;
                height: 130px;
                display: inline-flex;
                position: relative;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            
            &_info {
                display: inline-flex;
                flex-direction: column;
                margin-left: 50px;
    
                .top-offers__icon {
                    margin-bottom: 10px;
                }
            }
            
            &_offer-description {
                margin-top: 5px;
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 0.5px;
                color: $color-day-gray;
            }
        }
        
        &__category,
        &__geo,
        &__payments,
        &__approve {
            min-width: 150px;

            &-block {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
        
        &__geo {
            &__icon{
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 1px solid $color-day-bg;
            }
            &_info {
                height: 18px;
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
            
            &_logo {
                margin-right: 15px;
            }
            
            &_name {
                font-family: $font-secondary;
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.5px;
                color: $color-day-dark-gray2;
                white-space: nowrap;
            }
        }
        
        &__payments {
            &_price,
            &_money,
            &_webcoins {
                height: 18px;
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                
                font-family: $font-secondary;
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.5px;
                color: $color-day-dark-gray2;
                
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
            
            &_webcoins {
                margin-left: 10px;
                
                img {
                    margin-right: 5px;
                }
            }
            
            &_price {
                @include maxWidth(450) {
                    margin-left: 15px;
                }
            }
        }
        
        &__button {
            min-width: 210px;
            padding-right: 30px;
            
            &-block {
                width: 100%;
                display: inline-flex;
                justify-content: flex-end;
            }
            &_create {
                width: 200px;
                color: $color-day-black;
            }
        }
    }
    .offers__card-default {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        background-color: $color-day-white;
        padding: 30px 20px;
        position: relative;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        margin-bottom: 5px;
        @include break(sm){
            margin-bottom: 5px;
            margin-right: 5px;
            max-width: calc((100% - 5px) / 2);
            &:nth-child(2n + 2) {
                margin-right: 0;
            }
        }
        @include break(md) {
        
        }
        @include break(xl){
            max-width: calc((100% - 15px) / 4);
            &:nth-child(2n + 2) {
                margin-right: 5px;
            }
            &:nth-child(4n + 4) {
                margin-right: 0;
            }
        }
        &:hover {
            @include minWidth(630) {
                box-shadow: 0px 50px 100px rgba($color-day-black, 0.25);
            }
            z-index: $z-index-form-element;
        }
        &:active,
        &:focus {
            @include maxWidth(630) {
                box-shadow: none;
            }
        }
        &__offer-name{
            display: flex;
            align-items: center;
        }
        &_link {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
        }
        &__content {
            width: 100%;
            max-width: 300px;
            min-height: 670px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &-block {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            background-color: $color-day-bg;
            padding-top: 5px;
            @include break(sm){
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
        &__wrapper {
            max-width: calc((100% - 15px) / 4);
            min-width: 330px;
            max-height: 630px;
            flex: 1 1 24%;
            margin-right: 5px;
            margin-bottom: 5px;
            overflow: hidden;
            border-radius: 3px;
            &:nth-child(4n + 4) {
                margin-right: 0;
            }
            &:hover {
                overflow: visible;
            }
            @include maxWidth(1480) {
                max-width: calc((100% - 10px) / 3);
                &:nth-child(n) {
                    margin-right: 5px;
                }
                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }
            @include maxWidth(1146) {
                max-width: calc((100% - 5px) / 2);
                &:nth-child(n) {
                    margin-right: 5px;
                }
                &:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
            @include maxWidth(950) {
                min-width: 290px;
            }
            @include maxWidth(630) {
                min-width: 100%;
                width: 100%;
                max-height: initial;
                height: auto;
                overflow: visible;
                &:nth-child(n) {
                    margin-right: 0;
                }
            }
        }
        
        &__name_info {
            margin-left: 0;
            margin-top: 10px;
        }
        &__logo {
            position: relative;
            width: 200px;
            height: 200px;
            margin-bottom: 10px;
            img {
                min-width: 100%;
                min-height: 100%;
            }
            &__icon{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                background: white;
                border-radius: 3px;
                box-shadow: 0 1px 2px rgb(0 0 0 / 19%);
                position: absolute;
                top: 0;
                right: -10px;
            }
        }
        &__category {
            &-block {
                width: 100%;
                flex-flow: row wrap;
                justify-content: center;
                margin-top: 15px;
            }
        }
        &__offer-tag {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
        &__geo {
            &-block {
                width: 100%;
                margin-top: 15px;
                @include maxWidth(450) {
                    width: auto;
                }
            }
            
            &_info {
                width: 100%;
                justify-content: space-between;
            }
            
            &_country {
                display: flex;
                align-items: center;
            }
            &__icon{

            }
        }
        
        &__button {
            &-block {
                width: 100%;
                justify-content: center;
                margin-top: auto;
            }
            
            &_create {
                width: 100%;
            }
        }
    }
    
    .offers__table-default-block {
        margin-top: -10px;
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }
    
    .not--active {
        opacity: 0.4;
    }

</style>